import React from "react";
import "../css/style.css";
import Fade from "react-reveal/Fade";

function FirstPage() {
	return (
		<>
			<Fade>
				<div className="first-page-header"></div>
			</Fade>
		</>
	);
}

export default FirstPage;

