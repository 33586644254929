import React from "react";
import "../css/style.css";

function SecondPage() {
	return (
		<>
			{" "}
			<div className="second-page"></div>
		</>
	);
}

export default SecondPage;
