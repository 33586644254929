import React, { useState } from "react";
import ModalIntresseanmalning from "./modals/Modal_Intresseanmalning";
import "../css/style.css";

import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import Fade from "react-reveal/Fade";

function ContactForm() {
    require("dotenv").config();

	const [checkbox_checkedFirst, setCheckbox_checkedFirst] = useState(false);
	
	const [checkbox_first, setCheckbox_first] = useState(false);
	const [participateFirst, setParticipateFirst] = useState("Nej");
	const [checkbox_second, setCheckbox_second] = useState(false);
	const [participateSecond, setParticipateSecond] = useState("Nej");
	const [checkbox_third, setCheckbox_third] = useState(false);
	const [participateThird, setParticipateThird] = useState("Nej");
	

    const [checkbox_checked, setCheckbox_checked] = useState(false);
    const [btn_disabled, setBtn_disabled] = useState(true);
    const [fornamn, setFornamn] = useState(""); // '' is the initial state value
    const [efternamn, setEfternamn] = useState(""); // '' is the initial state value
    const [email, setEmail] = useState(""); // '' is the initial state value
    const [telefonnummer, setTelefonnummer] = useState(""); // '' is the initial state value
    const [message, setMessage] = useState(""); // '' is the initial state value
	const [participate, setParticipate] = useState("Nej");
	const [participate_choice, setParticipate_choice] = useState(false)

    // state = { btn_disabled: true, checkbox_checked: false, module: null };

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();

        axios.post(
            "https://gc2cx2zcp8.execute-api.eu-central-1.amazonaws.com/stage1/contact-us", {
                fornamn: fornamn,
                efternamn: efternamn,
                email: email,
                telefonnummer: telefonnummer,
                message: message,
				participateFirst: participateFirst,
				participateSecond: participateSecond,
				participateThird: participateThird,
                gdpr: "Jag accepterar att mina personuppgifter lagras och används för utskick av nyhetsbrev och inbjudningar. Jag accepterar att mitt namn och organisation finns med på en deltagarförteckning som delas ut i samband med evenemanget och att jag är synlig på den filmade versionen av webbinariet. Jag godkänner även att mina personuppgifter lagras och används för administration i samband med evenemanget.",
            }
        );
        // .then((res) => {
        // console.log(res);
        // console.log(res.data);
        // });
    };

	const ifCheckedParticipateChoiceFirst = (event) => {
		if (checkbox_first) {
            setCheckbox_first(false);
            setParticipateFirst("Nej");
            return;
        } else {
            setCheckbox_first(true);
            setParticipateFirst("Ja");
            return;
        }
	}
	const ifCheckedParticipateChoiceSecond = (event) => {
		console.log(participateSecond)

		if (checkbox_second) {
            setCheckbox_second(false);
			setParticipateSecond("Nej");
			console.log(participateSecond)
            return;
        } else {
            setCheckbox_second(true);
			setParticipateSecond("Ja");
			console.log(participateSecond)
            return;
        }
	}

	const ifCheckedParticipateChoiceThird = (event) => {
		if (checkbox_third) {
            setCheckbox_third(false);
            setParticipateThird("Nej");
            return;
        } else {
            setCheckbox_third(true);
            setParticipateThird("Ja");
            return;
        }
	}


    const ifChecked = (event) => {
        if (checkbox_checked) {
            setCheckbox_checked(false);
            setBtn_disabled(true);
            return;
        } else {

			setCheckbox_checked(true);
			setBtn_disabled(false);
			return
	}
            
    };

    return ( <>
        <Fade>
				<div className="info-header-2" id="anmalan">
					<h1>Anmäl er här:</h1>
				</div>
			</Fade> <Fade cascade>
        <div className="ContactForm">
					<Form
						noValidate
						validated={validated}
						onSubmit={handleSubmit}
					>
						<div className="formular">


							<Form.Group
								md="select"
								controlId="validationCustom01"
							>
								<Form.Control
									size="lg"
									required
									type="text"
									placeholder="Förnamn"
									value={fornamn}
									onInput={(e) => setFornamn(e.target.value)}
								/>
							</Form.Group>
							<Form.Group
								md="select"
								controlId="validationCustom02"
							>
								<Form.Control
									required
									size="lg"
									type="text"
									placeholder="Efternamn"
									value={efternamn}
									onInput={(e) =>
										setEfternamn(e.target.value)
									}
								/>
							</Form.Group>
							<Form.Group md="6" controlId="validationCustom03">
								<Form.Control
									type="text"
									placeholder="Email"
									required
									size="lg"
									value={email}
									onInput={(e) => setEmail(e.target.value)}
								/>
							</Form.Group>
							<Form.Group md="3" controlId="validationCustom04">
								<Form.Control
									type="text"
									placeholder="Telefonnummer"
									required
									size="lg"
									value={telefonnummer}
									onInput={(e) =>
										setTelefonnummer(e.target.value)
									}
								/>
							</Form.Group>

							<Form.Group controlId="exampleForm.ControlTextarea1">
								<Form.Control
									placeholder="(OPTIONAL) Eventuella förväntningar och tankar på Malmö Contech."
									as="textarea"
									rows="4"
									size="lg"
									value={message}
									onInput={(e) => setMessage(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="formBasicCheckbox2">
								<Form.Label></Form.Label>
								<Form.Check required size="lg">
									<Form.Check.Input
										type="checkbox"
										onChange={ifChecked}
									/>
									<Form.Check.Label
										type="checkbox"
										className="signup-checkbox"
										required
										size="md"
									>
										&nbsp;&nbsp; Jag accepterar att mina personuppgifter lagras och används för utskick av nyhetsbrev och inbjudningar.

Jag accepterar att mitt namn och organisation finns med på en deltagarförteckning som delas ut i samband med evenemanget och att jag är synlig på den filmade versionen av webbinariet. Jag godkänner även att mina personuppgifter lagras och används för administration i samband med evenemanget.
									</Form.Check.Label>
								</Form.Check>
							</Form.Group>
							<br />
						</div>

						<ModalIntresseanmalning
							name="Skicka"
							type="submit"
							disabled={btn_disabled}
							fornamn={fornamn}
							efternamn={efternamn}
							email={email}
							telefonnummer={telefonnummer}
							className="buttonRegistrera"
						/>
					</Form>
				</div> </Fade> </>
    );
}

export default ContactForm;