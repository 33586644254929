import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ModalIntresseanmalning = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        window.location.reload();
        setShow(false);
    };
    const handleShow = () => {
        if (
            props.fornamn === "" ||
            props.efternamn === "" ||
            props.telefonnummer === "" ||
            props.email === ""
        ) {
            setShow(false);
        } else {
            setShow(true);
        }
    };
    return ( <>
        <button
				type={props.type}
				disabled={props.disabled}
				onClick={handleShow}
				className="buttonRegistrera"
			>
				{props.name}
			</button> { " " }
        <Modal
				style={{ color: "grey" }}
				size="sm"
				centered
				show={show}
				onHide={handleClose}
			>
				<Modal.Header closeButton>Tack för er anmälan!</Modal.Header>{" "}
				<Modal.Body>
					<br />
					Ni är numera anmälda till Malmö Contech den 26 november!
					<br /> <br />
					Håll utkik efter kalenderkallelse i mailen. Glöm inte att
					kolla i skräpkorgen ifall ni inte har fått den inom ett par
					dagar. Hör annars av er till oss på info @malmocontech.se.{" "}
					<br /> <br /> - Malmö Contech <br />
					<br />{" "}
				</Modal.Body>{" "}
			</Modal> { " " } </>
    );
};

export default ModalIntresseanmalning;