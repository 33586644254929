import React from "react";
import "../css/style.css";
import Jump from "react-reveal/Jump";
import ListGroup from "react-bootstrap/ListGroup";
import { HashLink as Link } from "react-router-hash-link";

function InfoPage() {
  return (
    <>
      <Jump delay={500}>
        <div className="info-header">
          <Link
            to="./#info"
            scroll={(el) =>
              el.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            style={{ color: "white", textDecoration: "none" }}
          >
            <i className="far fa-arrow-alt-circle-down"></i>
          </Link>
        </div>{" "}
      </Jump>{" "}
      <div id="info" className="byggregler-pic">
        <div className="info-text-contech">
          <h1 className="info-header">
            Malmö Contech torsdag 26 november kl 16-17: <br/>Eftersnacksdiskussion "Från
            lärdom till praxis"
          </h1>
          <br />
          Välkommen på eftersnack om BIM Alliance frukostar "Digitalisering i
          det privata och offentliga fastighetsbolaget" och "Skånska
          digitaliseringsprojekt".
          <br />
          <br />          <br />

          <div className="info-button-box">
            <Link
              to="./#anmalan"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              style={{ color: "white", textDecoration: "none" }}
            >
              <button className="info-button">Anmäl er här!</button>
            </Link>
          </div>
          <br />

          <br />
          Malmö Contech bjuder in till en öppen diskussion om{" "}
          <b className="greenOnHover">"Hur går vi från lärdom till praxis?"</b>.
          <ListGroup variant="flush" style={{ margin: "2em" }}>
            &nbsp;&nbsp;&nbsp; <b>Frukostarnas presentationer:</b>
            <ListGroup.Item>
              <div
                style={{
                  marginLeft: "3em",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                <b className="greenOnHover">
                Förvaltningsuppdrag i ständig förändring (Serviceförvaltningen
                inom Stadsfastigheter på Malmö Stad)</b>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div
                style={{
                  marginLeft: "3em",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
               <b className="greenOnHover"> Digitalt vad är det egentligen? (ICA Fastigheter AB)</b>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div
                style={{
                  marginLeft: "3em",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                <b className="greenOnHover">                Nya sjukhusområdet i Malmö – utmaningar och digitala lösningar
                under projektering och tidiga skeden i ett komplext
                sjukhusprojekt (Regionfastigheter/Projtools)
                </b>

              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div
                style={{
                  marginLeft: "3em",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                <b className="greenOnHover">
                Projekt Triton – Hinder och möjligheter vid implementering och
                användning av digitalisering i produktion (Veidekke)
                </b></div>
            </ListGroup.Item>
          </ListGroup>
          <br />
          För att hitta dokumentation från tidigare evenemang,{" "}
          <a
            href="https://www.dropbox.com/sh/65qfh6f57844pbt/AAC2dFM7oYbkhxgAmWTwQjoba?dl=0"
            target="_blank"
            rel="noopener noreferrer"
            className="anchor-in-text"
          >
            se här.
          </a>
          {/* <br />
          <br />
          <div className="info-button-box">
            <Link
              to="./#anmalan"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              style={{ color: "white", textDecoration: "none" }}
            >
              <button className="info-button">Anmäl er här!</button>
            </Link>
          </div>
          <br />
          <br />
          Den <b className="greenOnHover">24 september kl. 16.00-17.00</b>{" "}
          arrangerar Malmö Contech en träff (online). <br /> <br />
          Vi följer som vi tidigare berättat BIM Alliance-teman från deras
          frukostseminarium, som denna gången är{" "}
         <i>Utbildning och kunskapslyft</i>.{" "}
          <a
            href="https://www.bimalliance.se/naetverk-och-moeten/kalendarium/200924-bimmalmo/"
            target="_blank"
            rel="noopener noreferrer"
            className="anchor-in-text"
          >
            Gå gärna in och anmäl er även till detta på BIM Alliance hemsida.
          </a>
          <br />
          <br />
          <ListGroup variant="flush" style={{ margin: "2em" }}>
          &nbsp;&nbsp;&nbsp;Agenda:
            <ListGroup.Item>
            <b className="greenOnHover">16.00 - 16.20</b> <div style={{ marginLeft:'7em', marginBottom:'1em'}}>Robert Bolton, Innovationsledare på Tyréns, berättar
              om hur det är att driva innovation och dess utmaningar.</div>
            </ListGroup.Item>
            <ListGroup.Item>
            <b className="greenOnHover">16.20 - 16.30</b> <div style={{marginLeft:'7em', marginBottom:'1em'}}>Genomgång av resultatet från workshop om
              "Modellbaserad information och dess juridiska förutsättningar".</div>
            </ListGroup.Item>
            <ListGroup.Item>
            <b className="greenOnHover">16.30 - 17.00</b> <div style={{marginLeft:'7em', marginBottom:'1em'}}>Diskussion kring behov av utbildning och
              kunskapslyft kring användandet av BIM-modeller för projektering,
              produktion och förvaltning.</div>
            </ListGroup.Item>
          </ListGroup>

          <br />
          Mer detaljer samt länk till möte kommer efter ni anmält er. Anmälan
          görs här nedan.
          <br />
          <br />
          Glada hösthälsningar,
          <br />
          Malmö Contech  */}
        </div>{" "}
      </div>{" "}
    </>
  );
}

export default InfoPage;
