import React from "react";
import "../css/style.css";
import Fade from "react-reveal/Fade";

function Footer() {
	return (
		<>
			<Fade>
				<div className="footer">
					<p className="copyright">
						<a href="mailto:info@malmocontech.se">
							info@malmocontech.se
						</a>
						<br />
						&copy; Copyright 2020 by Malmö Contech.
					</p>
				</div>

				<ul className="nav-bottom">
					<li className="nav-bottom__item">
						<a
							href="https://www.linkedin.com/company/malmo-contech/"
							className="nav-bottom__link"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-linkedin-in"></i>
						</a>
					</li>
				</ul>
			</Fade>
		</>
	);
}

export default Footer;
