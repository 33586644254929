import React from "react";
import "../css/style.css";
import ListGroup from "react-bootstrap/ListGroup";
import { HashLink as Link } from "react-router-hash-link";
import ReactPlayer from "react-player";

function PreviousEvents() {
  return (
    <>
      <div id="previous" className="previous-pic">
        <div className="previous-text-contech">
          <h1 className="previous-header">Tidigare events</h1>
          <br />

          <div style={{ marginLeft: "2em", marginBottom: "1em" }}>
            Byggeffektivitet, 2020-05-28
          </div>

          <ReactPlayer
            url="https://youtu.be/ZMHBEPUV4U4"
            playing={false}
            className="previous-video"
          />

          <div style={{ marginTop: "1em", marginLeft: "2em" }}>
            {/* För att hitta dokumentation från tidigare evenemang,{" "}
            <a
              href="https://www.dropbox.com/sh/65qfh6f57844pbt/AAC2dFM7oYbkhxgAmWTwQjoba?dl=0"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor-in-text"
            >
              se här.
            </a> */}
             
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default PreviousEvents;
