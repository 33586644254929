import React from "react";
import "./css/style.css";
import FirstPage from "./components/FirstPage";
import SecondPage from "./components/SecondPage";
import InfoPage from "./components/InfoPage";
import PreviousEvents from './components/PreviousEvents'
import { BrowserRouter } from "react-router-dom";
import Fade from "react-reveal/Fade";

import ContactForm from "./components/ContactForm";

import Footer from "./components/Footer";

function App() {
    return (
        <BrowserRouter>
      <div className="container">
        <Fade duration={3000}>
          <div className="background-box"></div>
        </Fade>
        <FirstPage />
        <SecondPage />
        <InfoPage />
        <ContactForm />
        <PreviousEvents />

        <Footer />
      </div>
    </BrowserRouter>
    );
}

export default App;